import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import useMobileMode from '../../../hooks/useMobileMode';
import SpaceInvaders from '../../molecules/space-invaders/space-invaders';

import './triple-max-content.scss';

const TripleMaxContent = ({ slug, visual, interaction, navigation }) => {
  const { isMobile } = useMobileMode();

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, next) => <>{next}</>,
    },
  };

  return (
    <section
      className="f-triple-max-content"
      style={{
        backgroundImage: `url("${visual.fondo[isMobile ? 1 : 0].file.url}")`,
      }}
    >
      <div className="f-triple-max-content__wrapper">
        <div className="f-triple-max-content__card">
          <img
            className="f-triple-max-content__card-image"
            src={visual.imagen[isMobile ? 1 : 0].file.url}
            alt={visual.imagen[isMobile ? 1 : 0].description}
          />
          <a
            className="f-triple-max-content__card-cta"
            target="_blank"
            rel="noopener noreferrer"
            href={navigation.registrationUrl}
          >
            {documentToReactComponents(interaction?.buttonText.json, options)}
          </a>
          <div className="f-triple-max-content__card-disclaimer">
            {documentToReactComponents(interaction?.disclaimer.json)}
          </div>
        </div>
        <SpaceInvaders slug={slug} />
      </div>
    </section>
  );
};

export default TripleMaxContent;
