import React, { useState } from 'react';

import Layout from '../../organisms/layout/layout';
import SEO from '../../../shared/components/seo';
import useMobileMode from '../../../hooks/useMobileMode';
import TripleMaxContent from '../../organisms/triple-max-content/triple-max-content';
import './game-triplemax-template.scss';

const GameTripleMaxTemplate = ({ pageContext }) => {
  const { meta, visual, interaction, navigation } = pageContext;

  const menuActive = 5;
  const [modalActive, setmodalActive] = useState(null);
  const { isMobile } = useMobileMode();

  const classes = {
    footer: 'f-game-triplemax-template__footer',
  };

  return (
    <>
      <SEO
        title={meta.title || ''}
        description={meta.description || ''}
        slug={meta.slug}
      />
      <Layout
        menuActive={menuActive}
        shopButtonActive={false}
        headerActive={!isMobile}
        setmodalActive={setmodalActive}
        modalActive={modalActive}
        title={meta.title || ''}
        slug={meta.slug}
        classes={classes}
      >
        <TripleMaxContent
          slug={meta.slug}
          visual={visual}
          interaction={interaction}
          navigation={navigation}
        />
      </Layout>
    </>
  );
};

export default GameTripleMaxTemplate;
