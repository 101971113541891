import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { siteUrl } from '../../../shared/utils/siteUrl';
import './space-invaders.scss';

const GAME = '/games/space-invaders/';

const SpaceInvaders = ({ slug }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.type = 'text/javascript';
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    loadScript(`${GAME}scripts/index.js`)
      .then(() => {
        setIsLoaded(true);
      })
      .catch((err) => {
        window.location.reload();
      });
  }, []);

  return (
    <div className="f-space-invaders">
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href={`${GAME}styles/index.css`}
        />
      </Helmet>
      {isLoaded ? (
        <essity-space-invaders
          end-url={`${siteUrl}/${slug}`}
          end-score-visible-time="2500"
          assets-path={`${GAME}assets-equador`}
          max-missiles="2"
          copy-level="Nivel"
          copy-score="Puntaje"
          copy-start-title="Controles"
          copy-shoot="Disparar/Espacio"
          copy-left-right="Izquierda/Derecha"
          copy-start-button="Empezar"
          copy-gameover="Fin del juego"
        />
      ) : (
        <div className="f-space-invaders__loader">
          <div className="f-space-invaders__loader__spinner" />
        </div>
      )}
    </div>
  );
};

export default SpaceInvaders;
